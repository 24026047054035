import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import { useEffect } from 'react';

const About = () => {
  const { language } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          pt: { xs: '180px', sm: '180px', md: '180px', lg: '120px' },
          pb: { xs: '30px', sm: '30px', md: '30px', lg: '30px' },
        }}
      >

        <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' }, height: 'auto' }}>
          <picture>
            <img
              src="/images/Chisiamo1.webp"
              alt="Piani"
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </picture>
        </Box>


        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: { xs: 4, sm: 6, md: 8, lg: 10 },
          }}
        >
          <Container>
            <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 } }}>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: '1.75rem', sm: '3rem', md: '3rem', lg: '3rem' },
                  color: '#ffffff',
                  textAlign: 'center',
                }}
              >
                {translations[language]["PersonalTitle"]}
              </Typography>
            </Box>

            <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg:20 } }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                  color: '#ffffff',
                  textAlign: 'center',
                  lineHeight: 1.6,
                }}
              >
                {translations[language]["PersonalIntro1"]}
                <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>CRH Money</span>
                {translations[language]["PersonalIntro2"]}
                <br />
                <br /><br />
                {translations[language]["PersonalCta1"]}
                <span >CRH Money</span>
                {translations[language]["PersonalCta2"]}
                <br />
              </Typography>
            </Box>

            <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 } }}>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: '1.75rem', sm: '3rem', md: '3rem', lg: '3rem' },
                  color: '#ffffff',
                  textAlign: 'center',
                }}
              >
                {translations[language]["BusinessTitle"]}
              </Typography>
            </Box>

            <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 } }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                  color: '#ffffff',
                  textAlign: 'center',
                  lineHeight: 1.6,
                }}
              >
                {translations[language]["BusinessIntro1"]}
                <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>CRH Money Business</span>
                {translations[language]["BusinessIntro2"]}
                <br />
                <br /><br />
                {translations[language]["BusinessCta1"]}
                <span>CRH Money</span>
                {translations[language]["BusinessCta2"]}
                <br />
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default About;
