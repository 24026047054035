

// src/translations.js

const translations = {
  en: {
    "cards": "Cards",
    "contacts": "Contacts",
    "home": "Home",
    "about_us": "About Us",
    "CRH Money": "CRH Money",
    "AZIENDA": "COMPANY",
    "Chi siamo": "About Us",
    "Valori": "Values",
    "LEGALE": "LEGAL",
    "Privacy": "Privacy",
    "Cookies": "Cookies",
    "Termini e Condizioni": "Terms and Conditions",
    "Carte": "Cards",
    'Wallets': 'Wallets',
    'MARKETING': 'MARKETING',
    'Per la Stampa': 'For the Press',
    'News': 'News',
    'Piani': 'Plans',
    'Costi': 'Costs',
    'Conti': 'Accounts',
    'Assistenza': 'Support',
    'Vendite': 'Sales',
    'more_than_a_payment_app_it_is_carta_reale': 'More than a payment app, it is CRH Money.',
    "Il tuo denaro e i tuoi dati sono al sicuro con CRH Money": "Your money and your data are safe with CRH Money",
    "Scopri un mondo esclusivo con una storia che inizia più di mille anni fa...": "Discover an exclusive world with a story that began over a thousand years ago...",
    'APRI IL CONTO': 'OPEN ACCOUNT',
    'Il tuo denaro e i tuoi dati': 'Your money and your data',
    'sono al sicuro con CRH Money': 'are safe with CRH Money',
    "Scopri un mondo esclusivo": "Discover an exclusive world",
    "con una storia che inizia": "with a story that began",
    "più di mille anni fa...": "over a thousand years ago...",
    "apri_il_conto": "OPEN AN ACCOUNT",
    'accesso_clienti': 'CUSTOMER ACCESS',
    "Un'esperienza esclusiva": "An exclusive experience",
    "di pagamento": "of payment",
    'Usa CRH Money, il conto corrente per la gestione finanziaria di tutti i giorni.': 'Use CRH Money, the payment account for everyday financial management.',
    "Usa CRH Money, il conto corrente": "Use CRH Money, the payment's APP ",
    "per la gestione finanziaria di tutti i giorni.": "for everyday financial management.",
    "Libertà": "Freedom",
    "Usa il tuo conto corrente senza limiti annuali, dallo smartphone o dal computer o dal tablet e paga con bonifici europei, internazionali e apri fino a 2 carte fisiche e 4 virtuali, aggiungila al tuo wallet e paga e preleva senza confini.": "Use your payment account without annual limits, from your smartphone or computer or tablet and pay with European, international transfers and open up to 2 physical cards and 14 virtual ones, add it to your wallet and pay and withdraw without limits.",
    "Sicurezza": "Security",
    "I tuoi soldi sono al sicuro in CRH Money, la migliore tecnologia informatica e lo schema di protezione dei fondi come da regole della Banca Centrale Europea, investiamo molto in sicurezza per la tua serenità.": "Your money is safe in CRH Money, the best computer technology and the fund protection scheme as per the rules of the European Central Bank, we invest a lot in security for your peace of mind.",
    "Ti assistiamo sempre, dall’ apertura del conto corrente alla risoluzione di ogni eventuale problema, la ricca documentazione e il supporto rapido via chat, telefono o mail, ti faranno apprezzare il nostro servizio premium.": "We always assist you, from the opening of the payment account to the resolution of any problems, the rich documentation and the quick support via chat, phone or email, will make you appreciate our premium service.",
    "Ricco di funzioni": "Rich in features",
    "Ricarica in modo semplice e rapido tramite bonifico. Ricarica in contanti** in oltre 16.000 punti vendita. Paga tasse *, Pago PA *, bolli auto *, acquista ricariche telefoniche di tutti gli operatori e/o dei tuoi servizi preferiti (viaggi, giochi e tanto altro).": "Recharge easily and quickly via bank transfer. Top up in cash in over 16,000 points of sale. Pay taxes, Pago PA, vehicle tax*, purchase phone top-ups from all operators and/or your favorite services (travel, games and much more).",
    'Conformità': 'Compliance',
    'e Sicurezza': 'and Security',
    'Conformità alle regole Europee': 'Compliance with European rules',
    'Controllo degli accessi ': 'Access control',
    'Certificazione Crest di cyber sicurezza': 'Crest certification in cyber security',
    'Password dinamiche per gli acquisti Online': 'Dynamic passwords for online purchases',
    'La nostra missione è permetter alle persone': 'Our mission is to allow people',
    "di vivere e lavorare ovunque,con semplicità,": 'to live and work anywhere, with simplicity,',
    'offrendoti la massima sicurezza.': "offering you maximum security.",
    "Apri il tuo conto": "Open your account",
    'in meno di 10 minuti': 'in less than 10 minutes',
    "Ricarica per contanti con semplicità, ovunque tu sia in Italia c’è un punto di ricarica vicino a te. Ti basterà mostrare il codice all’esercente e in pochi minuti vedrai il saldo sul tuo conto .": "Top up for cash with simplicity, wherever you are in Italy there is a charging point near you. Just show the code to the operator and in a few minutes you will see the balance on your account.",
    'Ricarica in oltre': 'Ricarica in oltre',
    '16.000 punti vendita': '16.000 punti vendita*',
    "Ricarica per contanti con semplicità, ovunque tu sia in Italia c’è un punto di ricarica vicino a te.": "Top up for cash with simplicity, wherever you are in Italy there is a charging point near you.",
    "Ti basterà mostrare il codice all’esercente e in pochi minuti vedrai il saldo sul tuo conto.": "Just show the code to the operator and in a few minutes you will see the balance on your account.",
    "CRH Money è sempre vicino a te": "CRH Money is always close to you",
    "*soggetto a termini e condizioni e limiti di ricarica come da disposizioni vigenti.": "*subject to terms and conditions and top-up limits as per current regulations.",
    'Scegli la tua': "Choose your",
    'CRH Money': 'CRH Money',
    'SCOPRI LE CARTE': 'DISCOVER THE CARDS',
    "* solo su invito": "* by invitation only",
    "Apple o Google per pagare e prelevare": "Apple or Google to pay and withdraw",
    "Aggiungi CRH Money al tuo wallet": "Add CRH Money to your wallet",
    "Attiva in 5 minuti": "Activate in 5 minutes",
    "Ti bastano un documento (carta d’Identità o Passaporto ) , una prova di indirizzo e un selfie per aprire il conto digitale al 100% di CRH Money, inoltre l’assistenza è sempre a tua disposizione per aiutarti": "All you need is a document (Identity card or Passport), proof of address and a selfie to open the 100% digital account of CRH Money, in addition, assistance is always available to help you",
    "Conto Europeo": "European Account",
    "Ti forniamo un conto con IBAN europeo per i tuoi trasferimenti senza limiti di importo in tutta Europa e nel mondo.": "We provide you with an account with a European IBAN for your transfers without amount limits throughout Europe and the world.",
    "Pagamenti utenze": "Bill payments",
    "Paga dall' App, tablet o dal computer le tue utenze, il bollo auto, PagoPA, i bollettini premarcati semplicemente in modo sicuro.": "Pay your bills, car tax, PagoPA, pre-printed bulletins simply and securely from the App, tablet or computer.",
    "I nostri conti sono assicurati fino a 100 000 euro e conformi allo schema sulla protezione dei fondi vigente in Europa.": "Our accounts are insured up to 100,000 euros and comply with the fund protection scheme in force in Europe.",
    "Carte Mastercard": "Mastercard Cards",
    "Ottieni fino a 4 carte virtuali e 2 fisiche, usale con il tuo smartphone o tablet per pagare e prelevare in libertà in tutto il mondo.": "Get up to 14 virtual cards and 2 physical cards, use them with your smartphone or tablet to pay and withdraw freely worldwide.",
    "WEB": "WEB",
    "Accedi a tutte le funzionalità anche dal Web senza costi aggiuntivi godendo di tutte le funzionalità dell’APP, facilmente.": "Access all the features also from the Web at no additional cost, enjoying all the features of the APP, easily.",
    "Scarica l'App CRH Money": "Download the CRH Money App",
    "Trovi CRH Money nell' APpp Store e su Google Play oppure inquadrando il Qr Code. Apri il tuo conto in 5 minuti, ottieni la tua carta virtuale per operare da subito": "You can find CRH Money in the App Store and on Google Play or by scanning the QR Code. Open your account in 5 minutes, get your virtual card to operate immediately",
    "Tutti i nostri conti includono l’accesso dal Web con un’interfaccia semplice che ti permette di effettuare tutte le operazioni consentite dall’ App, perfetto per i tablet, veloce e sicuro.": "All our accounts include access from the Web with a simple interface that allows you to perform all the operations allowed by the App, perfect for tablets, fast and secure.",
    "Accedi al tuo conto dal Web.": "Access your account from the Web.",
    "CRH Money è una Banca?": "Is CRH Money a Bank?",
    "Posso usarla dal computer?": "Can I use it from the computer?",
    "Posso Prelevare all'ATM?": "Can I withdraw from the ATM?",
    "Posso aggiungerlo a Google Pay o Apple Pay?": "Can I add it to Google Pay or Apple Pay?",
    "Che altre funzionalità offre CRH Money?": "What other features does CRH Money offer?",
    "Che assistenza offre CRH Money?": "What support does CRH Money offer?",
    "CRH Money non è una banca ma offre servizi bancari tecnologici tramite web e smartphone. Con il conto CRH Money puoi avere un IBAN europeo, eseguire e ricevere bonifici europei (SEPA) e internazionali (SWIFT), le operazioni bancarie sono garantite da Unlimit un’azienda globale con licenza di Istituto di moneta elettronica leader del settore dei servizi bancari ad alta tecnologia.": "CRH Money is not a bank but offers technological banking services via web and smartphone. With the CRH Money account you can have a European IBAN, make and receive European (SEPA) and international (SWIFT) transfers, banking operations are guaranteed by Unlimit a global company with a license of Electronic Money Institution leader in the high-tech banking services sector.",
    "CRH Money offre ai propri clienti l’accesso da APP Android e IOS e dal computer con tutti i browser da my.crhmoney.com senza costi aggiuntivi.": "CRH Money offers its customers access from Android and IOS APP and from the computer with all browsers from my.crhmoney.com at no additional cost.",
    "Con CRH Money puoi avere istantaneamente e senza costi una carta virtuale che ti permette tutte le operazioni di una carta fisica compreso il prelievo di contanti sugli ATM dotati di contactless.": "With CRH Money you can instantly and at no cost have a virtual card that allows you to perform all the operations of a physical card including cash withdrawals at ATMs equipped with contactless.",
    "Tutte le nostre carte sono sul circuito Mastercard Debit e possono essere usate con i wallet di Apple e Google.": "All our cards are on the Mastercard Debit circuit and can be used with Apple and Google wallets.",
    "CRH Money offre numerose altre funzionalità di pagamento come l’acquisto dei vouchers dei marchi più prestigiosi, le ricariche telefoniche di tutti gli operatori, pagamento di bollettini premarcati, Pago PA, MAV e RAV. Puoi avere la carta fisica e 7 carte virtuali per ogni piano.": " CRH Money offers many other payment features such as the purchase of vouchers from the most prestigious brands, phone top-ups from all operators, payment of pre-printed bulletins, Pago PA, MAV and RAV. You can have the physical card and 7 virtual cards for each plan.",
    "CRH Money offre un portale completo di domande e risposte e una chat con operatori umani in tempo reale per darti un assistenza rapida e un esperienza d’uso impareggiabile.": "CRH Money offers a complete portal of questions and answers and a chat with real-time human operators to give you quick support and an unparalleled user experience.",
    "I nostri conti sono assicurati fino a 100.000 euro e conformi allo schema sulla protezione dei fondi vigente in Europa.": "Our accounts are insured up to 100,000 euros and comply with the fund protection scheme in force in Europe.",
    'Ricarica': 'Top up',
    'in oltre 16.000': 'in over 16,000',
    'punti vendita': 'points of sale',
    'Terms and Conditions of Use of the Service': 'Terms and Conditions of Use of the Service',
    'About us': 'About us',
    'First Digital Trade Europe UAB, a company with 306129492 registration number and registered address at Kaykyos 18-10 01100 Vilnius Lithuania, you can contact us by email at contact@firstdigitaltrade.com': 'First Digital Trade Europe UAB, a company with 306129492 registration number and registered address at Kaykyos 18-10 01100 Vilnius Lithuania, you can contact us by email at contact@firstdigitaltrade.com',
    'Apri il conto': 'Open an account',
    'Accesso clienti': 'Customer access',
    'Scegli': 'Choose',
    'il piano di abbonamento': 'the subscription plan',
    'perfetto per te': 'perfect for you',
    'CRH Money offre 2 piani': 'CRH Money offers 2 plans',
    'per coprire tutte le tue esigenze': 'to cover all your needs',
    'per la gestione finanziaria di tutti i giorni': 'for everyday financial management',
    "Attraverso il calcio e le sue progettualità, Casa Reale Holding ha voluto comunicare un sistema valoriale capace di arrivare a tutti i suoi stakeholder con la possibilità di distinguersi su scala mondiale. Il Goal è il risultato del lavoro di squadra, passaggio fondamentale per raggiungere qualcosa di più grande. In campo e non solo. Lo spirito di Casa Reale Holding è guardare avanti, vivere avanti. Il mondo oggi ci chiede di metterci in gioco su un nuovo territorio, quello della capacità di avere un impatto. Non solo sul campo, ma anche nei contesti che ci circondano, facendo leva sui valori dello Sport per contribuire concretamente ad evolvere la Società e le nuove generazioni. Casa Reale Holding vuole ricoprire questo ruolo in prima persona.": "Through football and its projects, Casa Reale Holding wanted to communicate a value system capable of reaching all its stakeholders with the possibility of distinguishing itself on a global scale. The Goal is the result of teamwork, a fundamental step to achieve something bigger. On the field and beyond. The spirit of Casa Reale Holding is to look ahead, to live ahead. The world today asks us to get involved in a new territory, that of the ability to have an impact. Not only on the field, but also in the contexts that surround us, leveraging the values of Sport to contribute concretely to evolving Society and the new generations. Casa Reale Holding wants to play this role firsthand.",
    "L’inclusione finanziaria è parte del nostro impegno e CRH Money è il servizio di pagamenti all’avanguardia creato per accompagnare chi condivide i nostri valori e i nostri obiettivi.": "Financial inclusion is part of our commitment and CRH Money is the cutting-edge payment service created to accompany those who share our values and our goals.",
    'Per sapere di più, visita il sito:': 'To learn more, visit the website:  ',
    'Contatti': 'Contacts',
    'Al momento non ci sono news. Per rimanere aggiornato sulle iniziative CRH Money, iscriviti alla nostra newsletter.': 'There are currently no news. To stay updated on CRH Money initiatives, subscribe to our newsletter.',
    "Tutti gli eventi e le novità dell’ esclusivo mondo CRH Money direttamente nella tua mail": "All the events and news of the exclusive world of CRH Money directly in your email",
    "Il miglior modo di comunicare con noi è il seguente :": "The best way to communicate with us is as follows:",
    "Via email : servizioclienti@crhmoney.com": "By email: Servizioclienti@crhmoney.com",
    "Via messaggi istantanei : tramite la chat istantanea che trovi in basso a destra.": "Via instant messaging: through the instant chat you find at the bottom right.",
    "Via ticket compilando il modulo sottostante.": "Via ticket by filling out the form below.",
    "I messaggi saranno automaticamente inviati alle persone più idonee a risponderti e risponderemo in massimo 48 ore": "The messages will be automatically sent to the most suitable people to answer you and we will reply within 48 hours",
    "Privacy EN": "Privacy EN",
    "Terms and Conditions EN": "T&C EN",
    'Carta White : Apri carta White a 9,90 al mese': 'White Card: Open White Card at 9.90 per month',
    'Carta Blu : Apri Carta Blu a 19,90 al mese': 'Blue Card: Open Blue Card at 19.90 per month',
    'Iscriviti alla Newsletter': 'Subscribe to the Newsletter',
    "Termini e condizioni Googlepay": "Googlepay T&C",
    "Termini e condizioni Applepay": "Applepay T&C",
    "Il conto (nome del marchio) e Mastercard sono emessi da Unilimint, autorizzato dalla Banca di Cipro con la licenza di istituzione di moneta elettronica per emettere e-money ed è membro dello Schema Mastercard. Si prega di notare che i prodotti di moneta elettronica non sono coperti dal Sistema di Assicurazione dei Depositi della Repubblica di Cipro. Assicuriamo che qualsiasi fondo ricevuto da voi sia tenuto in un conto segregato in modo che, nel caso in cui Unilimint diventi insolvente, i vostri fondi saranno protetti contro le richieste fatte dai nostri creditori.": "The (brand name) Account and Mastercard is issued by Unlimint, authorised by the Bank of Cyprus under the electronic money institution license to issue e-money and is a member of Mastercard Scheme. Please note that electronic money products are not covered by the Deposit Guarantee & Resolution of Credit & Other Institutions Scheme. The funds loaded into your (brand name) Account do not constitute a deposit account and you will not earn interest on the funds you are held in a segregated account so that should Unlimint be insolvent your funds will be protected against claims made by our creditors.",
    "Termini e condizioni Unlimit EN": "T&C Unlimit EN",
    "Non regaliamo punti, non ti diamo premi, non abbiamo canoni zero, perché a differenza degli altri servizi finanziari on line, non usiamo i tuoi soldi, che vengono depositati in conti di salvaguardia regolamentati dalla BCE.": "We don't give points, we don't give you prizes, we don't have zero fees, because unlike other online financial services, we don't use your money, which is deposited in safeguard accounts regulated by the ECB.",
    "recharge_functions": "Recharge easily and quickly with instant bank transfer, use PagoPA*, and purchase top-ups for your favorite services, from travel and games to phone credits from all providers.",
    "Ti forniamo un conto con IBAN europeo (Cipro) per i tuoi trasferimenti senza limiti di importo in tutta Europa e nel mondo": "We provide you with a European IBAN account (Cyprus) for your transfers with no amount limits throughout Europe and the world.",
    "Usa il tuo conto corrente senza limiti annuali, dallo smartphone, dal computer o dal tablet e paga con bonifici europei, internazionali. Apri fino a 2 carte fisiche e 4 virtuali, aggiungile al tuo wallet e paga e preleva senza confini.": "Use your current account without annual limits from your smartphone, computer, or tablet, and make payments with European and international transfers. Open up to 2 physical cards and 14 virtual cards, add them to your wallet, and make payments and withdrawals without boundaries.",
    "Investiamo molto in sicurezza per la tua serenità. I tuoi soldi sono al sicuro in CRH Money, usiamo la migliore tecnologia informatica e lo schema di protezione dei fondi come da regole della Banca Centrale Europea.": "We invest heavily in security for your peace of mind. Your money is safe with CRH Money, as we use the best computer technology and the fund protection scheme in accordance with the rules of the European Central Bank",
    'offrendo la massima sicurezza.': "offering the highest level of security.",
    "Casa Reale Holding SPA attraverso il calcio e le sue progettualità,  ha voluto comunicare un sistema valoriale capace di arrivare a tutti i suoi stakeholder con la possibilità di distinguersi su scala mondiale. Il Goal è il risultato del lavoro di squadra, passaggio fondamentale per raggiungere qualcosa di più grande. In campo e non solo. Lo spirito di Casa Reale  è guardare avanti, vivere avanti. Il mondo oggi ci chiede di metterci in gioco su un nuovo territorio, quello della capacità di avere un impatto. Non solo sul campo, ma anche nei contesti che ci circondano, facendo leva sui valori dello Sport per contribuire concretamente ad evolvere la Società e le nuove generazioni. Casa Reale Holding spa vuole ricoprire questo ruolo in prima persona.": "Casa Reale Holding SPA through football and its projects, wanted to communicate a value system capable of reaching all its stakeholders with the possibility of distinguishing itself on a global scale. The Goal is the result of teamwork, a fundamental step to achieve something bigger. On the field and beyond. The spirit of Casa Reale is to look ahead, to live ahead. The world today asks us to get involved in a new territory, that of the ability to have an impact. Not only on the field, but also in the contexts that surround us, leveraging the values of Sport to contribute concretely to evolving Society and the new generations. Casa Reale Holding spa wants to play this role firsthand.",
    "Eccellenza e competenza nel Wealth Management. Investiamo ogni giorno per migliorare il nostro servizio e lavoriamo per rendere il mondo un posto migliore.": "Excellence and expertise in Wealth Management. We invest every day to improve our service and work to make the world a better place.",
    "footer_disclaimer": "The CRH Money account and Mastercard are issued by Unlimit, authorised by the Bank of Cyprus under the electronic money institution license to issue e-money and is a member of Mastercard Scheme. Please note that electronic money products are not covered by the Deposit Guarantee & Resolution of Credit & Other Institutions Scheme. The funds loaded into your CRH Money account do not constitute a deposit account and you will not earn interest on the funds you are held in a segregated account so that should Unlimit be insolvent your funds will be protected against claims made by our creditors.",
    'Scopri le carte': 'Discover the cards',
    'il conto Premium': 'the Premium account',
    'Apri CRH Money White': 'Open White CRH Gold ',
    'Per usurfuire di limiti estesi': 'To enjoy extended limits',
    "è possibile attivare la CRH Money Blu": "it is possible to activate the CRH Money Blue",
    "aggiungendo solo 10 euro al mese": "by adding only 10 euros per month",
    'direttamente dall’app.': 'directly from the app.',
    'A soli 19,90 al mese avrai i seguenti servizi:': 'For only 19.90 per month you will have the following services:',
    "CRH Money non è una banca, perché non utilizza il tuo denaro, ma offre servizi bancari esclusivi e tecnologici tramite web e smartphone. Con il conto CRH Money puoi avere un IBAN europeo, eseguire e ricevere bonifici europei (SEPA) e internazionali (SWIFT), le operazioni bancarie sono garantite da Unlimit, un’azienda globale con licenza di Istituto di moneta elettronica, leader del settore dei servizi bancari ad alta tecnologia": "CRH Money is a payment App, because it does not use your money, but offers exclusive and technological banking services via web and smartphone. With the CRH Money account you can have a European IBAN, make and receive European (SEPA) and international (SWIFT) transfers, banking operations are guaranteed by Unlimit, a global company with a license of Electronic Money Institution, leader in the high-tech banking services sector.",
    "CRH Money offre un portale completo di domande e risposte e una chat con operatori  in tempo reale per darti un’assistenza rapida e un’esperienza d’uso impareggiabile.": "CRH Money offers a complete portal of questions and answers and a chat with real-time operators to give you quick support and an unparalleled user experience.",
    "Con CRH Money puoi avere fino a due carte fisiche per poter prelevare in tutti gli istituti bancari e puoi ottenere istantaneamente e senza costi fino a 14 carte virtuali che ti permettono tutte le operazioni di una carta fisica compreso il prelievo di contanti sugli ATM dotati di contactless.": "With CRH Money you can have up to two physical cards to withdraw from all banks and you can instantly and at no cost get up to 14 virtual cards that allow you to perform all the operations of a physical card including cash withdrawals at ATMs equipped with contactless.",
    'Guida Utente EN': 'User Guide EN',
    "Casa Reale Holding SPA attraverso il calcio e le sue progettualità, ha voluto comunicare un sistema valoriale capace di arrivare a tutti i suoi stakeholder con la possibilità di distinguersi su scala mondiale. Il Goal è il risultato del lavoro di squadra, passaggio fondamentale per raggiungere qualcosa di più grande. In campo e non solo. Lo spirito di Casa Reale è guardare avanti, vivere avanti. Il mondo oggi ci chiede di metterci in gioco su un nuovo territorio, quello della capacità di avere un impatto. Non solo sul campo, ma anche nei contesti che ci circondano, facendo leva sui valori dello Sport per contribuire concretamente ad evolvere la Società e le nuove generazioni. Casa Reale Holding spa vuole ricoprire questo ruolo in prima persona.": "Casa Reale Holding SPA through football and its projects, wanted to communicate a value system capable of reaching all its stakeholders with the possibility of distinguishing itself on a global scale. The Goal is the result of teamwork, a fundamental step to achieve something bigger. On the field and beyond. The spirit of Casa Reale is to look ahead, to live ahead. The world today asks us to get involved in a new territory, that of the ability to have an impact. Not only on the field, but also in the contexts that surround us, leveraging the values of Sport to contribute concretely to evolving Society and the new generations. Casa Reale Holding spa wants to play this role firsthand.",
    "L'inclusione finanziaria è il nostro principale impegno, la concessione dell'utilizzo del brand CRH Money esclusivamente nella qualità di copyright, gestita da importanti partnership, ha come obiettivo la realizzazione di un servizio di pagamenti all'avanguardia volto ad accompagnare chi condivide i nostri valori ei nostri traguardi.": "Financial inclusion is our main commitment, the granting of the use of the CRH Money brand exclusively in the quality of copyright, managed by important partnerships, aims to create a cutting-edge payment service aimed at accompanying those who share our values and our goals.",
    "service_available_from": "*Service available from September 15, 2024.",
    "massimale_giornaliero": "**Daily limit of €500 per day for a total of €5,000 per month.",
    "Paga dall' App, tablet o dal computer le tue utenze, il bollo auto, PagoPA*, i bollettini premarcati semplicemente in modo sicuro.": "Pay your bills, car tax, PagoPA*, pre-printed bulletins simply and securely from the App, tablet or computer.",
    "Con CRH Money puoi avere fino a due carte fisiche per poter prelevare in tutti gli istituti bancari e puoi ottenere istantaneamente e senza costi fino a 14 carte virtuali che ti permettono tutte le operazioni di una carta fisica compreso il prelievo di contanti** sugli ATM dotati di contactless.": "With CRH Money you can have up to two physical cards to withdraw from all banks and you can instantly and at no cost get up to 14 virtual cards that allow you to perform all the operations of a physical card including cash withdrawals** at ATMs equipped with contactless.",
    "Cookie EN": "Cookie policy EN",
    "CRH Money Cost Document": "CRH Money Document Costs",
    "Cookie text": "This website stores cookies on your computer, which are used to collect information about your use of the site and remember your behavior in the future. Cookies are used to improve the site itself and to offer a personalized service, both on the site and through other media. For more information about cookies, please see the privacy policy. If you do not accept its use, your behavior during your visit will not be tracked, but a single cookie will be used in your browser to remember that you have chosen not to record browsing information.",
    "Accept": "Accept",
    "Decline": "Decline",
    "Crh1": "CRH Money : A Step Forward Into the Future",

    "Crh2": "We are pleased to share with you an important step in our growth journey: Casa Reale Holding SpA has chosen to project its brand into an international dimension, transferring it and becoming CRH Money. This change represents our desire to look to the future, while keeping our roots and identity firm and unchanged.",

    "Crh3": "The internationalization of our name reflects our constant commitment: to build an increasingly innovative, technological product capable of anticipating market needs, without ever losing sight of the values that distinguish us.",

    "Crh4": "With CRH Money, we are building a future where our services and products will be increasingly advanced, intuitive, and aligned with your needs. Every day we work to offer you cutting-edge financial solutions, with the aim of staying one step ahead of the market.",

    "Crh5": "Continuous Improvement",

    "Crh61": "Constant innovation",
    "Crh62": ": Technology evolves, and so do our services. In the coming months, we will introduce new features and tools to make managing your finances even simpler and more efficient.",

    "Crh71": "An international vision",
    "Crh72": ": CRH Money is our way of opening up to the world, bringing with us the excellence and quality that have always characterized us.",

    "Crh9": "Your Rights and PSD2 Compliance",
    "Crh90": "In line with the European Union's PSD2 Directive, we wish to provide you with all necessary information regarding this transition:",

    "Crh91": "Data security and protection",
    "Crh92": ": Your personal and financial data will continue to be protected according to the highest security standards and in compliance with the General Data Protection Regulation (GDPR).",

    "Crh101": "Service continuity",
    "Crh102": ": There will be no interruptions or changes to the services you use. All existing contracts remain valid and unchanged.",

    "Crh111": "Right of withdrawal",
    "Crh112": ": If you do not wish to continue with our services, you have the right to withdraw without any penalty, as provided by current regulations. For more information, you can contact our customer service.",

    "Crh121": "Transparency",
    "Crh122": ": For further details, you can consult our updated Terms and Conditions here.",
    "Crh15": "Discover CRH Money",
    "Crh161": "We invite you to visit",
    "Crh162": "to learn more about CRH Money and the innovations we are preparing for you.",
    "Crh17": "Our team is always available to answer any questions or clarifications through the usual support channels.",
    "Crh18": "Thank you for being part of this journey. With CRH Money, the future is already here.",
    "landing": "Rebrending",
    "AboutTitle1": "Exclusivity is Personal",
    "AboutText1": "Entering the world of ",
    "AboutText2": " means accessing an exclusive service, designed to offer you security, efficiency, and tailored benefits. Thanks to a perfect balance between tradition and innovation, we guarantee you a premium experience, supported by dedicated assistance and cutting-edge tools.",
    "AboutText3": "Start building your financial future with ",
    "AboutText4": " today.",
    "AboutTitle2": "Exclusivity is Business",
    "AboutText5": "Trusting ",
    "AboutText6": " means accessing advanced financial solutions, designed to meet the needs of companies and professionals with VAT numbers. We offer tailored tools to simplify financial management, ensuring security, efficiency, and dedicated support.",
    "AboutText7": "Choose ",
    "AboutText8": " to grow your Business with a reliable and exclusive partner.",
    "PersonalTitle": "Exclusivity is Personal",
  "PersonalIntro1": "Entering the world of ",
  "PersonalIntro2": " means accessing an exclusive service, designed to offer you security, efficiency, and tailored benefits. Thanks to a perfect balance between tradition and innovation, we guarantee you a premium experience, supported by dedicated assistance and cutting-edge tools.",
  "PersonalCta1": "Start building your financial future with ",
  "PersonalCta2": " today.",
  "BusinessTitle": "Exclusivity is Business",
  "BusinessIntro1": "Trusting ",
  "BusinessIntro2": " means accessing advanced financial solutions, designed to meet the needs of companies and professionals with VAT numbers. We offer tailored tools to simplify financial management, ensuring security, efficiency, and dedicated support.",
  "BusinessCta1": "Choose ",
  "BusinessCta2": " to grow your Business with a reliable and exclusive partner."
  },
  it: {
    "contacts": "Contatti",
    "cards": "Carte",
    "home": "Home",
    "about_us": "Chi Siamo",
    "CRH Money": "CRH Money",
    "Piani": "Piani",
    "AZIENDA": "AZIENDA",
    "Chi siamo": "Chi siamo",
    "Valori": "Valori",
    "LEGALE": "LEGALE",
    "Privacy": "Privacy",
    "Cookies": "Cookies",
    "Termini e Condizioni": "Termini e Condizioni",
    "Carte": "Carte",
    'Wallets': 'Portafogli',
    'MARKETING': 'MARKETING',
    'Per la Stampa': 'Per la Stampa',
    'News': 'News',
    'Costi': 'Costi',
    'Conti': 'Conti',
    'Assistenza': 'Assistenza',
    'Vendite': 'Vendite',
    'more_than_a_payment_app_it_is_carta_reale': 'Più di un conto, è CRH Money.',
    "Il tuo denaro e i tuoi dati sono": "Il tuo denaro e i tuoi dati sono",
    "al sicuro con CRH Money": "al sicuro con CRH Money",
    "Scopri un mondo esclusivo": "Scopri un mondo esclusivo",
    "con una storia che inizia": "con una storia che inizia",
    "più di mille anni fa...": "più di mille anni fa...",
    'APRI IL CONTO': 'APRI IL CONTO',
    'Il tuo denaro e i tuoi dati': "Il tuo denaro e i tuoi dati",
    'sono al sicuro con CRH Money': 'sono al sicuro con CRH Money',
    "apri_il_conto": "APRI IL CONTO",
    'accesso_clienti': 'ACCESSO CLIENTI',
    "di pagamento": " di pagamento",
    "Un'esperienza esclusiva": "Un'esperienza esclusiva",
    "Usa CRH Money, il conto corrente": "Usa CRH Money, la APP di pagamento",
    "per la gestione finanziaria di tutti i giorni.": "per la gestione finanziaria di tutti i giorni.",
    "Libertà": "Libertà",
    "Usa il tuo conto corrente senza limiti annuali, dallo smartphone o dal computer o dal tablet e paga con bonifici europei, internazionali e apri fino a 2 carte fisiche e 4 virtuali, aggiungila al tuo wallet e paga e preleva senza confini.": "Usa il tuo conto senza limiti annuali, dallo smartphone o dal computer o dal tablet e paga con bonifici europei, internazionali e apri fino a 2 carte fisiche e 14 virtuali, aggiungila al tuo wallet e paga e preleva senza confini.",
    "Sicurezza": "Sicurezza",
    "I tuoi soldi sono al sicuro in CRH Money, la migliore tecnologia informatica e lo schema di protezione dei fondi come da regole della Banca Centrale Europea, investiamo molto in sicurezza per la tua serenità.": "I tuoi soldi sono al sicuro in CRH Money, la migliore tecnologia informatica e lo schema di protezione dei fondi come da regole della Banca Centrale Europea, investiamo molto in sicurezza per la tua serenità.",
    "Ti assistiamo sempre, dall’ apertura del conto corrente alla risoluzione di ogni eventuale problema, la ricca documentazione e il supporto rapido via chat, telefono o mail, ti faranno apprezzare il nostro servizio premium.": "Ti assistiamo sempre, dall’ apertura del conto alla risoluzione di ogni eventuale problema, la ricca documentazione e il supporto rapido via chat, telefono o mail, ti faranno apprezzare il nostro servizio premium.",
    "Ricco di funzioni": "Ricco di funzioni",
    "recharge_functions": "Ricarica in modo semplice e rapido tramite bonifico. Ricarica in contanti** in oltre 16.000 punti vendita. Pago PA*, acquista ricariche telefoniche di tutti gli operatori e/o dei tuoi servizi preferiti (viaggi, giochi e tanto altro).",
    'Conformità': 'Conformità',
    'e Sicurezza': 'e Sicurezza',
    'Conformità alle regole Europee': 'Conformità alle regole Europee',
    'Controllo degli accessi ': 'Controllo degli accessi',
    'Certificazione Crest di cyber sicurezza': 'Certificazione Crest di cyber sicurezza',
    'Password dinamiche per gli acquisti Online': 'Password dinamiche per gli acquisti Online',
    'La nostra missione è permetter alle persone': 'La nostra missione è permetter alle persone',
    "di vivere e lavorare ovunque,con semplicità,": "di vivere e lavorare ovunque,con semplicità,",
    'offrendoti la massima sicurezza.': 'offrendoti la massima sicurezza.',
    'Apri il tuo conto': 'Apri il tuo conto',
    'in meno di 10 minuti': 'in meno di 10 minuti',
    "Ricarica per contanti con semplicità, ovunque tu sia in Italia c’è un punto di ricarica vicino a te. Ti basterà mostrare il codice all’esercente e in pochi minuti vedrai il saldo sul tuo conto .": "Ricarica per contanti con semplicità, ovunque tu sia in Italia c’è un punto di ricarica vicino a te. Ti basterà mostrare il codice all’esercente e in pochi minuti vedrai il saldo sul tuo conto .",
    'Ricarica in oltre': 'Ricarica in oltre',
    '16.000 punti vendita': '16.000 punti vendita*',
    "Ricarica per contanti con semplicità, ovunque tu sia in Italia c’è un punto di ricarica vicino a te.": "Ricarica per contanti con semplicità, ovunque tu sia in Italia c’è un punto di ricarica vicino a te.",
    "Ti basterà mostrare il codice all’esercente e in pochi minuti vedrai il saldo sul tuo conto.": "Ti basterà mostrare il codice all’esercente e in pochi minuti vedrai il saldo sul tuo conto.",
    "CRH Money è sempre vicino a te": "CRH Money è sempre vicino a te",
    "*soggetto a termini e condizioni e limiti di ricarica come da disposizioni vigenti.": "*soggetto a termini e condizioni e limiti di ricarica come da disposizioni vigenti.",
    'Scegli la tua': "Scegli la tua",
    'CRH Money': 'CRH Money',
    'SCOPRI LE CARTE': 'SCOPRI LE CARTE',
    "* solo su invito": "* solo su invito",
    "Apple o Google per pagare e prelevare": "Apple o Google per pagare e prelevare",
    "Aggiungi CRH Money al tuo wallet": "Aggiungi CRH Money al tuo wallet",
    "Attiva in 5 minuti": "Attiva in 5 minuti",
    "Ti bastano un documento (carta d’Identità o Passaporto ) , una prova di indirizzo e un selfie per aprire il conto digitale al 100% di CRH Money, inoltre l’assistenza è sempre a tua disposizione per aiutarti": "Ti bastano un documento (carta d’Identità o Passaporto) , una prova di indirizzo e un selfie per aprire il conto digitale al 100% di CRH Money, inoltre l’assistenza è sempre a tua disposizione per aiutarti",
    "Conto Europeo": "Conto Europeo",
    "Ti forniamo un conto con IBAN europeo per i tuoi trasferimenti senza limiti di importo in tutta Europa e nel mondo.": "Ti forniamo un conto con IBAN europeo per i tuoi trasferimenti senza limiti di importo in tutta Europa e nel mondo.",
    "Pagamenti utenze": "Pagamenti utenze",
    "Paga dall' App, tablet o dal computer le tue utenze, il bollo auto, PagoPA, i bollettini premarcati semplicemente in modo sicuro.": "Paga dall' App, tablet o dal computer le tue utenze, il bollo auto, PagoPA, i bollettini premarcati semplicemente in modo sicuro.",
    "I nostri conti sono assicurati fino a 100 000 euro e conformi allo schema sulla protezione dei fondi vigente in Europa.": "I nostri conti sono assicurati fino a 100 000 euro e conformi allo schema sulla protezione dei fondi vigente in Europa.",
    "Carte Mastercard": "Carte Mastercard",
    "Ottieni fino a 4 carte virtuali e 2 fisiche, usale con il tuo smartphone o tablet per pagare e prelevare in libertà in tutto il mondo.": "Ottieni fino a 14 carte virtuali e 2 fisiche, usale con il tuo smartphone o tablet per pagare e prelevare in libertà in tutto il mondo.",
    "WEB": "WEB",
    "Accedi a tutte le funzionalità anche dal Web senza costi aggiuntivi godendo di tutte le funzionalità dell’APP, facilmente.": "Accedi a tutte le funzionalità anche dal Web senza costi aggiuntivi godendo di tutte le funzionalità dell’APP, facilmente.",
    "Scarica l'App CRH Money": "Scarica l'App CRH Money",
    "Trovi CRH Money nell' APpp Store e su Google Play oppure inquadrando il Qr Code. Apri il tuo conto in 5 minuti, ottieni la tua carta virtuale per operare da subito": "Trovi CRH Money nell' App Store e su Google Play oppure inquadrando il Qr Code. Apri il tuo conto in 5 minuti, ottieni la tua carta virtuale per operare da subito",
    "Tutti i nostri conti includono l’accesso dal Web con un’interfaccia semplice che ti permette di effettuare tutte le operazioni consentite dall’ App, perfetto per i tablet, veloce e sicuro.": "Tutti i nostri conti includono l’accesso dal Web con un’interfaccia semplice che ti permette di effettuare tutte le operazioni consentite dall’ App, perfetto per i tablet, veloce e sicuro.",
    "Accedi al tuo conto dal Web.": "Accedi al tuo conto dal Web.",
    "CRH Money è una Banca?": "CRH Money è una Banca?",
    "Posso usarla dal computer?": "Posso usarla dal computer?",
    "Posso Prelevare all'ATM?": "Posso Prelevare all'ATM?",
    "Posso aggiungerlo a Google Pay o Apple Pay?": "Posso aggiungerlo a Google Pay o Apple Pay?",
    "Che altre funzionalità offre CRH Money?": "Che altre funzionalità offre CRH Money?",
    "Che assistenza offre CRH Money?": "Che assistenza offre CRH Money?",
    "CRH Money non è una banca ma offre servizi bancari tecnologici tramite web e smartphone. Con il conto CRH Money puoi avere un IBAN europeo, eseguire e ricevere bonifici europei (SEPA) e internazionali (SWIFT), le operazioni bancarie sono garantite da Unlimit un’azienda globale con licenza di Istituto di moneta elettronica leader del settore dei servizi bancari ad alta tecnologia.": "CRH Money non è una banca ma offre servizi bancari tecnologici tramite web e smartphone. Con il conto CRH Money puoi avere un IBAN europeo, eseguire e ricevere bonifici europei (SEPA) e internazionali (SWIFT), le operazioni bancarie sono garantite da Unlimit un’azienda globale con licenza di Istituto di moneta elettronica leader del settore dei servizi bancari ad alta tecnologia.",
    "CRH Money offre ai propri clienti l’accesso da APP Android e IOS e dal computer con tutti i browser da my.crhmoney.com senza costi aggiuntivi.": "CRH Money offre ai propri clienti l’accesso da APP Android e IOS e dal computer con tutti i browser da my.crhmoney.com senza costi aggiuntivi.",
    "Con CRH Money puoi avere istantaneamente e senza costi una carta virtuale che ti permette tutte le operazioni di una carta fisica compreso il prelievo di contanti sugli ATM dotati di contactless.": "Con CRH Money puoi avere istantaneamente e senza costi una carta virtuale che ti permette tutte le operazioni di una carta fisica compreso il prelievo di contanti sugli ATM dotati di contactless.",
    "Tutte le nostre carte sono sul circuito Mastercard Debit e possono essere usate con i wallet di Apple e Google.": "Tutte le nostre carte sono sul circuito Mastercard Debit e possono essere usate con i wallet di Apple e Google.",
    "CRH Money offre numerose altre funzionalità di pagamento come l’acquisto dei vouchers dei marchi più prestigiosi, le ricariche telefoniche di tutti gli operatori, pagamento di bollettini premarcati, Pago PA, MAV e RAV. Puoi avere la carta fisica e 7 carte virtuali per ogni piano.": "CRH Money offre numerose altre funzionalità di pagamento come l’acquisto dei vouchers dei marchi più prestigiosi, le ricariche telefoniche di tutti gli operatori, pagamento di bollettini premarcati, Pago PA, MAV e RAV. Puoi avere la carta fisica e 7 carte virtuali per ogni piano.",
    "CRH Money offre un portale completo di domande e risposte e una chat con operatori umani in tempo reale per darti un assistenza rapida e un esperienza d’uso impareggiabile.": "CRH Money offre un portale completo di domande e risposte e una chat con operatori umani in tempo reale per darti un'assistenza rapida e un'esperienza d’uso impareggiabile.",
    "I nostri conti sono assicurati fino a 100.000 euro e conformi allo schema sulla protezione dei fondi vigente in Europa.": "I nostri conti sono assicurati fino a 100.000 euro e conformi allo schema sulla protezione dei fondi vigente in Europa.",
    'Ricarica': 'Ricarica',
    'in oltre 16.000': 'in oltre 16.000',
    'punti vendita': 'punti vendita',
    'Terms and Conditions of Use of the Service': 'Termini e Condizioni di Utilizzo del Servizio',
    'About us': 'Chi siamo',
    'First Digital Trade Europe UAB, a company with 306129492 registration number and registered address at Kaykyos 18-10 01100 Vilnius Lithuania, you can contact us by email at contact@firstdigitaltrade.com': 'First Digital Trade Europe UAB, una società con numero di registrazione 306129492 e sede legale in Kaykyos 18-10 01100 Vilnius Lituania, puoi contattarci via email all\'indirizzo contact@firstdigitaltrade.com',
    'Apri il conto': 'Apri il conto',
    'Accesso clienti': 'Accesso clienti',
    'Scegli': 'Scegli',
    'il piano di abbonamento': 'il piano di abbonamento',
    'perfetto per te': 'perfetto per te',
    'CRH Money offre 2 piani': 'CRH Money offre 2 piani',
    'per coprire tutte le tue esigenze': 'per coprire tutte le tue esigenze',
    'per la gestione finanziaria di tutti i giorni': 'per la gestione finanziaria di tutti i giorni',
    "Attraverso il calcio e le sue progettualità, Casa Reale Holding ha voluto comunicare un sistema valoriale capace di arrivare a tutti i suoi stakeholder con la possibilità di distinguersi su scala mondiale. Il Goal è il risultato del lavoro di squadra, passaggio fondamentale per raggiungere qualcosa di più grande. In campo e non solo. Lo spirito di Casa Reale Holding è guardare avanti, vivere avanti. Il mondo oggi ci chiede di metterci in gioco su un nuovo territorio, quello della capacità di avere un impatto. Non solo sul campo, ma anche nei contesti che ci circondano, facendo leva sui valori dello Sport per contribuire concretamente ad evolvere la Società e le nuove generazioni. Casa Reale Holding vuole ricoprire questo ruolo in prima persona.": "Attraverso il calcio e le sue progettualità, Casa Reale Holding ha voluto comunicare un sistema valoriale capace di arrivare a tutti i suoi stakeholder con la possibilità di distinguersi su scala mondiale. Il Goal è il risultato del lavoro di squadra, passaggio fondamentale per raggiungere qualcosa di più grande. In campo e non solo. Lo spirito di Casa Reale Holding è guardare avanti, vivere avanti. Il mondo oggi ci chiede di metterci in gioco su un nuovo territorio, quello della capacità di avere un impatto. Non solo sul campo, ma anche nei contesti che ci circondano, facendo leva sui valori dello Sport per contribuire concretamente ad evolvere la Società e le nuove generazioni. Casa Reale Holding vuole ricoprire questo ruolo in prima persona.",
    "L’inclusione finanziaria è parte del nostro impegno e CRH Money è il servizio di pagamenti all’avanguardia creato per accompagnare chi condivide i nostri valori e i nostri obiettivi.": "L’inclusione finanziaria è parte del nostro impegno e CRH Money è il servizio di pagamenti all’avanguardia creato per accompagnare chi condivide i nostri valori e i nostri obiettivi.",
    'Per sapere di più, visita il sito:': 'Per sapere di più, visita il sito: ',
    'Contatti': 'Contatti',
    'Al momento non ci sono news. Per rimanere aggiornato sulle iniziative CRH Money, iscriviti alla nostra newsletter.': 'Al momento non ci sono news. Per rimanere aggiornato sulle iniziative CRH Money, iscriviti alla nostra newsletter.',
    "Tutti gli eventi e le novità dell’ esclusivo mondo CRH Money direttamente nella tua mail": "Tutti gli eventi e le novità dell’ esclusivo mondo CRH Money direttamente nella tua mail",
    "Il miglior modo di comunicare con noi è il seguente :": "Il miglior modo di comunicare con noi è il seguente :",
    "Via email : servizioclienti@crhmoney.com": "Via email: Servizioclienti@crhmoney.com",
    "Via messaggi istantanei : tramite la chat istantanea che trovi in basso a destra.": "Via messaggi istantanei : tramite la chat istantanea che trovi in basso a destra.",
    "Via ticket compilando il modulo sottostante.": "Via ticket compilando il modulo sottostante.",
    "I messaggi saranno automaticamente inviati alle persone più idonee a risponderti e risponderemo in massimo 48 ore": "I messaggi saranno automaticamente inviati alle persone più idonee a risponderti e risponderemo in massimo 48 ore",
    "Privacy IT": "Privacy IT",
    "Termini e condizioni IT": "T&C IT",
    'Carta White : Apri carta White a 9,90 al mese': 'Carta White : Apri carta White a 9,90 al mese',
    'Carta Blu : Apri Carta Blu a 19,90 al mese': 'Carta Blu : Apri Carta Blu a 19,90 al mese',
    'Iscriviti alla Newsletter': 'Iscriviti alla Newsletter',
    "Termini e condizioni Googlepay": "T&C Googlepay",
    "Termini e condizioni Applepay": "T&C Applepay",
    "Termini e condizioni Unlimit It": "T&C Unlimit IT",
    "Ricarica in modo semplice e rapido tramite bonifico istantaneo, paga tasse *, Pago PA *, bolli auto *, acquista ricariche dei tuoi servizi preferiti, da i viaggi ai giochi alle ricariche telefoniche di tutti gli operatori.": "Ricarica in modo semplice e rapido tramite bonifico istantaneo, paga tasse *, Pago PA *, bolli auto *, acquista ricariche dei tuoi servizi preferiti, da i viaggi ai giochi alle ricariche telefoniche di tutti gli operatori.",
    "Ti forniamo un conto con IBAN europeo (Cipro) per i tuoi trasferimenti senza limiti di importo in tutta Europa e nel mondo": "Ti forniamo un conto con IBAN europeo (Cipro) per i tuoi trasferimenti senza limiti di importo in tutta Europa e nel mondo.",
    "Il conto (nome del marchio) e Mastercard sono emessi da Unilimint, autorizzato dalla Banca di Cipro con la licenza di istituzione di moneta elettronica per emettere e-money ed è membro dello Schema Mastercard. Si prega di notare che i prodotti di moneta elettronica non sono coperti dal Sistema di Assicurazione dei Depositi della Repubblica di Cipro. Assicuriamo che qualsiasi fondo ricevuto da voi sia tenuto in un conto segregato in modo che, nel caso in cui Unilimint diventi insolvente, i vostri fondi saranno protetti contro le richieste fatte dai nostri creditori.": "Il conto (nome del marchio) e Mastercard sono emessi da Unilimint, autorizzato dalla Banca di Cipro con la licenza di istituzione di moneta elettronica per emettere e-money ed è membro dello Schema Mastercard. Si prega di notare che i prodotti di moneta elettronica non sono coperti dal Sistema di Assicurazione dei Depositi della Repubblica di Cipro. Assicuriamo che qualsiasi fondo ricevuto da voi sia tenuto in un conto segregato in modo che, nel caso in cui Unilimint diventi insolvente, i vostri fondi saranno protetti contro le richieste fatte dai nostri creditori.",
    "Non regaliamo punti, non ti diamo premi, non abbiamo canoni zero, perché a differenza degli altri servizi finanziari on line, non usiamo i tuoi soldi, che vengono depositati in conti di salvaguardia regolamentati dalla BCE.": "Non regaliamo punti, non ti diamo premi, non abbiamo canoni zero, perché a differenza degli altri servizi finanziari on line, non usiamo i tuoi soldi, che vengono depositati in conti di salvaguardia regolamentati dalla BCE.",
    "Usa il tuo conto corrente senza limiti annuali, dallo smartphone, dal computer o dal tablet e paga con bonifici europei, internazionali. Apri fino a 2 carte fisiche e 4 virtuali, aggiungile al tuo wallet e paga e preleva senza confini.": "Usa il tuo conto senza limiti annuali, dallo smartphone, dal computer o dal tablet e paga con bonifici europei, internazionali. Apri fino a 2 carte fisiche e 14 virtuali, aggiungile al tuo wallet e paga e preleva senza confini.",
    "Investiamo molto in sicurezza per la tua serenità. I tuoi soldi sono al sicuro in CRH Money, usiamo la migliore tecnologia informatica e lo schema di protezione dei fondi come da regole della Banca Centrale Europea.": "Investiamo molto in sicurezza per la tua serenità. I tuoi soldi sono al sicuro in CRH Money, usiamo la migliore tecnologia informatica e lo schema di protezione dei fondi come da regole della Banca Centrale Europea.",
    'offrendo la massima sicurezza.': 'offrendo la massima sicurezza.',
    "Casa Reale Holding SPA attraverso il calcio e le sue progettualità,  ha voluto comunicare un sistema valoriale capace di arrivare a tutti i suoi stakeholder con la possibilità di distinguersi su scala mondiale. Il Goal è il risultato del lavoro di squadra, passaggio fondamentale per raggiungere qualcosa di più grande. In campo e non solo. Lo spirito di Casa Reale  è guardare avanti, vivere avanti. Il mondo oggi ci chiede di metterci in gioco su un nuovo territorio, quello della capacità di avere un impatto. Non solo sul campo, ma anche nei contesti che ci circondano, facendo leva sui valori dello Sport per contribuire concretamente ad evolvere la Società e le nuove generazioni. Casa Reale Holding spa vuole ricoprire questo ruolo in prima persona.": "Casa Reale Holding SPA attraverso il calcio e le sue progettualità,  ha voluto comunicare un sistema valoriale capace di arrivare a tutti i suoi stakeholder con la possibilità di distinguersi su scala mondiale. Il Goal è il risultato del lavoro di squadra, passaggio fondamentale per raggiungere qualcosa di più grande. In campo e non solo. Lo spirito di Casa Reale  è guardare avanti, vivere avanti. Il mondo oggi ci chiede di metterci in gioco su un nuovo territorio, quello della capacità di avere un impatto. Non solo sul campo, ma anche nei contesti che ci circondano, facendo leva sui valori dello Sport per contribuire concretamente ad evolvere la Società e le nuove generazioni. Casa Reale Holding spa vuole ricoprire questo ruolo in prima persona.",
    "Eccellenza e competenza nel Wealth Management. Investiamo ogni giorno per migliorare il nostro servizio e lavoriamo per rendere il mondo un posto migliore.": "Eccellenza e competenza nel Wealth Management. Investiamo ogni giorno per migliorare il nostro servizio e lavoriamo per rendere il mondo un posto migliore.",
    "footer_disclaimer": "Il conto CRH Money e Mastercard sono emessi da Unlimit, autorizzato dalla Banca di Cipro con la licenza di istituzione di moneta elettronica per emettere e-money ed è membro dello Schema Mastercard. Si prega di notare che i prodotti di moneta elettronica non sono coperti dal Sistema di Assicurazione dei Depositi della Repubblica di Cipro. Assicuriamo che qualsiasi fondo ricevuto da voi sia tenuto in un conto segregato in modo che, nel caso in cui Unlimit diventi insolvente, i vostri fondi saranno protetti contro le richieste fatte dai nostri creditori.",
    'Scopri le carte': 'Scopri le carte',
    'Apri CRH Money White': 'Apri CRH Money Gold',
    'a soli 9,90 euro al mese.': 'a soli 9,90 euro al mese.',
    'il conto Premium': 'il conto Premium',
    'Per usurfuire di limiti estesi': 'Per usurfuire di limiti estesi',
    'è possibile attivare la CRH Money Blu': 'è possibile attivare la CRH Money Blu',
    'aggiungendo solo 10 euro al mese': 'aggiungendo solo 10 euro al mese',
    "direttamente dall’app.": "direttamente dall’app.",
    'A soli 19,90 al mese avrai i seguenti servizi:': 'A soli 19,90 al mese avrai i seguenti servizi:',
    "CRH Money non è una banca, perché non utilizza il tuo denaro, ma offre servizi bancari esclusivi e tecnologici tramite web e smartphone. Con il conto CRH Money puoi avere un IBAN europeo, eseguire e ricevere bonifici europei (SEPA) e internazionali (SWIFT), le operazioni bancarie sono garantite da Unlimit, un’azienda globale con licenza di Istituto di moneta elettronica, leader del settore dei servizi bancari ad alta tecnologia": "CRH Money è un'App di pagamento, perché non utilizza il tuo denaro, ma offre servizi bancari esclusivi e tecnologici tramite web e smartphone. Con il conto CRH Money puoi avere un IBAN europeo, eseguire e ricevere bonifici europei (SEPA) e internazionali (SWIFT), le operazioni bancarie sono garantite da Unlimit, un’azienda globale con licenza di Istituto di moneta elettronica, leader del settore dei servizi bancari ad alta tecnologia.",
    "CRH Money offre un portale completo di domande e risposte e una chat con operatori  in tempo reale per darti un’assistenza rapida e un’esperienza d’uso impareggiabile.": "CRH Money offre un portale completo di domande e risposte e una chat con operatori  in tempo reale per darti un’assistenza rapida e un’esperienza d’uso impareggiabile.",
    "Con CRH Money puoi avere fino a due carte fisiche per poter prelevare in tutti gli istituti bancari e puoi ottenere istantaneamente e senza costi fino a 14 carte virtuali che ti permettono tutte le operazioni di una carta fisica compreso il prelievo di contanti sugli ATM dotati di contactless.": "Con CRH Money puoi avere fino a due carte fisiche per poter prelevare in tutti gli istituti bancari e puoi ottenere istantaneamente e senza costi fino a 14 carte virtuali che ti permettono tutte le operazioni di una carta fisica compreso il prelievo di contanti sugli ATM dotati di contactless.",
    'Guida Utente IT': 'Guida Utente IT',
    "Casa Reale Holding SPA attraverso il calcio e le sue progettualità, ha voluto comunicare un sistema valoriale capace di arrivare a tutti i suoi stakeholder con la possibilità di distinguersi su scala mondiale. Il Goal è il risultato del lavoro di squadra, passaggio fondamentale per raggiungere qualcosa di più grande. In campo e non solo. Lo spirito di Casa Reale è guardare avanti, vivere avanti. Il mondo oggi ci chiede di metterci in gioco su un nuovo territorio, quello della capacità di avere un impatto. Non solo sul campo, ma anche nei contesti che ci circondano, facendo leva sui valori dello Sport per contribuire concretamente ad evolvere la Società e le nuove generazioni. Casa Reale Holding spa vuole ricoprire questo ruolo in prima persona.": "Casa Reale Holding SPA attraverso il calcio e le sue progettualità, ha voluto comunicare un sistema valoriale capace di arrivare a tutti i suoi stakeholder con la possibilità di distinguersi su scala mondiale. Il Goal è il risultato del lavoro di squadra, passaggio fondamentale per raggiungere qualcosa di più grande. In campo e non solo. Lo spirito di Casa Reale è guardare avanti, vivere avanti. Il mondo oggi ci chiede di metterci in gioco su un nuovo territorio, quello della capacità di avere un impatto. Non solo sul campo, ma anche nei contesti che ci circondano, facendo leva sui valori dello Sport per contribuire concretamente ad evolvere la Società e le nuove generazioni. Casa Reale Holding spa vuole ricoprire questo ruolo in prima persona.",
    "L'inclusione finanziaria è il nostro principale impegno, la concessione dell'utilizzo del brand CRH Money esclusivamente nella qualità di copyright, gestita da importanti partnership, ha come obiettivo la realizzazione di un servizio di pagamenti all'avanguardia volto ad accompagnare chi condivide i nostri valori ei nostri traguardi.": "L'inclusione finanziaria è il nostro principale impegno, la concessione dell'utilizzo del brand CRH Money esclusivamente nella qualità di copyright, gestita da importanti partnership, ha come obiettivo la realizzazione di un servizio di pagamenti all'avanguardia volto ad accompagnare chi condivide i nostri valori e i nostri traguardi.",
    "service_available_from": "*Servizio disponibile dal giorno 15 di Settembre 2024.",
    "massimale_giornaliero": "**Massimale giornaliero di 500€ al giorno per un totale cumulato di 5.000€ al mese.",
    "Paga dall' App, tablet o dal computer le tue utenze, il bollo auto, PagoPA*, i bollettini premarcati semplicemente in modo sicuro.": "Paga dall' App, tablet o dal computer le tue utenze, il bollo auto, PagoPA*, i bollettini premarcati semplicemente in modo sicuro.",
    "Ricarica in modo semplice e rapido tramite bonifico. Ricarica in contanti** in oltre 16.000 punti vendita. paga tasse*, Pago PA*, bolli auto*, acquista ricariche telefoniche di tutti gli operatori e/o dei tuoi servizi preferiti (viaggi, giochi e tanto altro).": "Ricarica in modo semplice e rapido tramite bonifico. Ricarica in contanti** in oltre 16.000 punti vendita. Paga tasse*, Pago PA*, bolli auto*, acquista ricariche telefoniche di tutti gli operatori e/o dei tuoi servizi preferiti (viaggi, giochi e tanto altro).",
    "Con CRH Money puoi avere fino a due carte fisiche per poter prelevare in tutti gli istituti bancari e puoi ottenere istantaneamente e senza costi fino a 14 carte virtuali che ti permettono tutte le operazioni di una carta fisica compreso il prelievo di contanti** sugli ATM dotati di contactless.": "Con CRH Money puoi avere fino a due carte fisiche per poter prelevare in tutti gli istituti bancari e puoi ottenere istantaneamente e senza costi fino a 14 carte virtuali che ti permettono tutte le operazioni di una carta fisica compreso il prelievo di contanti** sugli ATM dotati di contactless.",
    "Cookie IT": "Cookie policy IT",
    "Documento sui costi di CRH Money": "Documento sui costi di CRH Money",
    "Cookie text": "Il presente sito Web archivia cookie sul computer dell'utente, che vengono utilizzati per raccogliere informazioni sull'utilizzo del sito e ricordare i comportamenti dell'utente in futuro. I cookie servono a migliorare il sito stesso e a offrire un servizio personalizzato, sia sul sito che tramite altri supporti. Per ulteriori informazioni sui cookie, consultare l'informativa sulla privacy. Se non si accetta l'utilizzo, non verrà tenuta traccia del comportamento durante visita, ma verrà utilizzato un unico cookie nel browser per ricordare che si è scelto di non registrare informazioni sulla navigazione.",
    "Accept": "Accetta",
    "Decline": "Declina",
    "Crh1": "CRH Money : Un Passo Avanti Verso il Futuro",
    "Crh2": "Siamo lieti di condividere con voi un importante passo nel nostro percorso di crescita: Casa Reale Holding SpA ha scelto di proiettare il proprio brand in una dimensione internazionale, trasferendolo e diventando CRH Money. Questo cambiamento rappresenta la nostra volontà di guardare al futuro, mantenendo salde le nostre radici e la nostra identità, che restano immutate.",
    "Crh3": "L'internazionalizzazione del nostro nome è il riflesso del nostro impegno costante: costruire un prodotto sempre più innovativo, tecnologico e in grado di anticipare le esigenze del mercato, senza mai perdere di vista i valori che ci contraddistinguono.",
    "Crh4": "Con CRH Money, stiamo costruendo un futuro in cui i nostri servizi e prodotti saranno sempre più avanzati, intuitivi e in linea con le vostre necessità. Ogni giorno lavoriamo per offrirvi soluzioni finanziarie d'avanguardia, con l'obiettivo di essere sempre un passo avanti rispetto al mercato.",
    "Crh5": "Un Miglioramento Continuo",
    "Crh61": "Innovazione costante",
    "Crh62": ": La tecnologia evolve, e con essa anche i nostri servizi. Nei prossimi mesi, introdurremo nuove funzionalità e strumenti per rendere la gestione delle vostre finanze ancora più semplice ed efficiente.",
    "Crh71": "Una visione internazionale",
    "Crh72": ": CRH Money è il nostro modo di aprirci al mondo, portando con noi l'eccellenza e la qualità che da sempre ci caratterizzano.",
    "Crh9": "I Vostri Diritti e la Conformità alla PSD2",
    "Crh90": "In linea con la Direttiva PSD2 dell'Unione Europea, desideriamo fornirvi tutte le informazioni necessarie riguardo a questa transizione:",
    "Crh91": "Sicurezza e protezione dei dati",
    "Crh92": ": I vostri dati personali e finanziari continueranno a essere protetti secondo i più alti standard di sicurezza e in conformità con il Regolamento Generale sulla Protezione dei Dati (GDPR).",
    "Crh101": "Continuità dei servizi",
    "Crh102": ": Non ci saranno interruzioni o modifiche ai servizi che utilizzate. Tutti i contratti esistenti rimangono validi e invariati.",
    "Crh111": "Diritto di recesso",
    "Crh112": ": Se non desiderate proseguire con i nostri servizi, avete il diritto di recedere senza alcuna penalità, come previsto dalla normativa vigente. Per maggiori informazioni, potete contattare il nostro servizio clienti.",
    "Crh121": "Trasparenza",
    "Crh122": ": Per ulteriori dettagli, potete consultare i nostri Termini e Condizioni aggiornati qui.",
    "Crh15": "Scoprite CRH Money",
    "Crh161": "Vi invitiamo a visitare",
    "Crh162": "per scoprire di più su CRH Money e sulle novità che stiamo preparando per voi.",
    "Crh17": "Il nostro team è sempre a vostra disposizione per rispondere a qualsiasi domanda o chiarimento attraverso i consueti canali di assistenza.",
    "Crh18": "Grazie per essere parte di questo viaggio. Con CRH Money, il futuro è già qui.",
    "landing": "CRH Money",
    "About6": "L'esclusività è Personale",
    "AboutTitle1": "L'esclusività è Personale",
    "AboutText1": "Entrare nel mondo ",
    "AboutText2": " significa accedere a un servizio esclusivo, pensato per offrirti sicurezza, efficienza e vantaggi su misura. Grazie a un perfetto equilibrio tra tradizione e innovazione, ti garantiamo un'esperienza premium, supportata da un'assistenza dedicata e strumenti all'avanguardia.",
    "AboutText3": "Inizia oggi a costruire il tuo futuro finanziario con ",
    "AboutText4": ".",
    "AboutTitle2": "L'esclusività è Business",
    "AboutText5": "Affidarsi a ",
    "AboutText6": " significa accedere a soluzioni finanziarie avanzate, progettate per soddisfare le esigenze di aziende e professionisti con Partita IVA. Offriamo strumenti su misura per semplificare la gestione finanziaria, garantendo sicurezza, efficienza e un supporto dedicato.",
    "AboutText7": "Scegli ",
    "AboutText8": " per far crescere il tuo Business con un partner affidabile ed esclusivo.",
    "PersonalTitle": "L'esclusività è Personale",
    "PersonalIntro1": "Entrare nel mondo ",
    "PersonalIntro2": " significa accedere a un servizio esclusivo, pensato per offrirti sicurezza, efficienza e vantaggi su misura. Grazie a un perfetto equilibrio tra tradizione e innovazione, ti garantiamo un'esperienza premium, supportata da un'assistenza dedicata e strumenti all'avanguardia.",
    "PersonalCta1": "Inizia oggi a costruire il tuo futuro finanziario con ",
    "PersonalCta2": ".",
    "BusinessTitle": "L'esclusività è Business",
    "BusinessIntro1": "Affidarsi a ",
    "BusinessIntro2": " significa accedere a soluzioni finanziarie avanzate, progettate per soddisfare le esigenze di aziende e professionisti con Partita IVA. Offriamo strumenti su misura per semplificare la gestione finanziaria, garantendo sicurezza, efficienza e un supporto dedicato.",
    "BusinessCta1": "Scegli ",
    "BusinessCta2": " per far crescere il tuo Business con un partner affidabile ed esclusivo."
  },

};



export default translations;
